import Lazyloading from '../../services/lazy-loading/lazyLoading';
import getWindowWidth from '../../helpers/getWindowWidth';

class SliderPublicationsLoader {
	constructor(targetSelector, callback) {
		this.targetSelector = targetSelector;
		this.callback = callback;
		this.nextButtons = document.querySelectorAll('.arrow__button_next');

		this.init();
	}

	init() {
		let options = {};

		if (getWindowWidth >= 1024) {
			options = {
				root: null,
				rootMargin: '0px',
				threshold: 1,
			};
		} else {
			options = {
				root: null,
				rootMargin: '0px',
				threshold: 0.1,
			};
		}

		const targetElements = document.querySelectorAll(this.targetSelector);

		targetElements.forEach((targetElement) => {
			this.observer = new IntersectionObserver(this.handleIntersection.bind(this), options);

			this.observer.observe(targetElement);
		});
	}

	handleIntersection(entries, observer) {
		entries.forEach(async (entry) => {
			if (entry.isIntersecting) {
				observer.unobserve(entry.target);
				const result = await this.callback(entry.target);

				if (result) {
					this.modifyElement(entry.target, result);
				}
			}
		});
	}

	modifyElement(element, result) {
		const parantNode = element?.parentNode;
		if (result?.html) {
			const range = document.createRange();
			range.selectNode(element);

			if (result?.link) {
				element.dataset.link = result?.link;
			} else {
				element.remove();
			}

			setTimeout(() => {
				const fragment = range.createContextualFragment(result.html + element.outerHTML);
				parantNode?.replaceChild(fragment, element);
				const lastSlide = parantNode?.querySelector('.articles-slider__slide_last-slide');
				new Lazyloading('img[loading=lazy]').init();
				this.nextButtons?.forEach((button) => {
					button.removeAttribute('disabled');
				});
				if (result?.link) {
					this.observer.observe(lastSlide);
					lastSlide.classList.add('new_observe');
				}
			}, 300);
		}
	}
}

new SliderPublicationsLoader('.articles-slider__slide_last-slide', async (target) => {
	try {
		const nextButtons = target?.parentNode?.parentNode?.querySelectorAll('.arrow__button_next');
		const params = new URLSearchParams();
		params.append('stack', target?.parentNode?.classList.toString()?.includes('articles-slider__stack'));

		nextButtons.forEach((button) => {
			button.setAttribute('disabled', true);
		});

		const response = await fetch(`${target?.dataset.link}?${params.toString()}`);
		const data = await response.json();

		return { ...data };
	} catch (error) {
		console.error('Error when executing the request', error);
		return {};
	}
});
