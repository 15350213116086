class Socials {
	static defaultSocialsShareSettings = {
		fb: {
			url: '',
			isVisible: false,
			keys: {
				url: 'u',
			},
		},
		vk: {
			title: 'ВКонтакте',
			url: 'http://vk.com/share.php',
			isVisible: true,
			keys: {
				url: 'url',
				title: 'title',
				image: 'image',
			},
		},
		ok: {
			title: 'Одноклассники',
			url: 'https://connect.ok.ru/offer',
			isVisible: true,
			// additionalKey: 'st.cmd=addShare&st.s=1',
			keys: {
				url: 'url',
				title: 'title',
				// description: 'st.comments',
				image: 'imageUrl',
			},
		},
		tg: {
			title: 'Telegram',
			url: 'https://telegram.me/share/url',
			isVisible: true,
			keys: {
				url: 'url',
				title: 'text',
			},
		},
		tw: {
			url: '',
			isVisible: false,
			keys: {
				url: 'url',
				title: 'text',
			},
		},
		vb: {
			title: 'Viber',
			url: 'viber://forward',
			isVisible: true,
			keys: {
				messangerText: 'text',
			},
		},
		wa: {
			title: 'WhtastApp',
			url: 'https://api.whatsapp.com/send',
			isVisible: true,
			keys: {
				messangerText: 'text',
			},
		},
		dzen: {
			url: 'https://dzen.ru/avtovzglyad.ru',
			isVisible: false,
			keys: {
				messangerText: 'text',
			},
		},
	};

	static defaultSocialsSettings = {
		fb: {
			url: '',
			isVisible: false,
		},
		vk: {
			url: 'https://vk.com/avtovzglyad',
			isVisible: true,
		},
		ok: {
			url: 'https://ok.ru/avtovzglyad',
			isVisible: true,
		},
		tg: {
			url: 'https://t.me/s/avtovzglyad_ru',
			isVisible: true,
		},
		twitter: {
			url: '',
			isVisible: false,
		},
		viber: {
			url: '',
			isVisible: false,
		},
		whatsapp: {
			url: '',
			isVisible: false,
		},
		dzen: {
			url: 'https://dzen.ru/avtovzglyad.ru',
			isVisible: true,
		},
	};

	static defaultPageSettings = {
		url: location.href,
		messangerText: 'https://www.avtovzglyad.ru/',
		title: document.title,
	};

	static settings = {
		shareLinkClass: '.share__link',
		shareDisabledLinkClass: 'share__link_disabled',
	};

	/**
	 * Возвращает ссылку на социальную сеть
	 *
	 * @static
	 * @param {*} buttonSettings
	 * @param {*} mode
	 * @return {*}
	 * @memberof Socials
	 */
	static getSocialUrlByName(buttonSettings, mode) {
		const social = buttonSettings[mode];
		if (social && social.isVisible) {
			return social.url;
		}
		return '';
	}

	/**
	 * Возвращает объект с параметрами социальной сети
	 *
	 * @static
	 * @param {*} buttonSettings
	 * @param {*} mode
	 * @return {*}
	 * @memberof Socials
	 */
	static getSocialItemByName(buttonSettings, mode) {
		const social = buttonSettings[mode];
		if (social && social.isVisible) {
			return social;
		}
		return '';
	}

	/**
	 * Возвращает ссылку на страницу социальных сетей поделиться
	 *
	 * @param {*} social
	 * @param {*} parameters
	 * @return {*}
	 * @memberof Socials
	 */
	static #getUrl(social, parameters) {
		if (!social.url) return null;
		let url = social.url;
		if ('additionalKey' in social) url += `?${social.additionalKey}`;

		Object.keys(social.keys).forEach((key, index) => {
			if (key in parameters) {
				url += index === 0 && 'url' in social.keys ? '?' : '&';

				if (key === 'title' && !('url' in social.keys)) {
					url += `${social.keys[key]}=${encodeURIComponent(parameters[key] + ' ' + parameters['url'])}`;
				} else {
					url += `${social.keys[key]}=${encodeURIComponent(parameters[key])}`;
				}
			}
		});

		return url;
	}

	/**
	 * Инициализирует ссылки поделиться на страницы социальных сетей
	 *
	 * @static
	 * @param {*} shareElementNodeOrClass
	 * @param {*} [buttonSettings=this.defaultSocialsShareSettings]
	 * @param {*} [pageSettings=this.defaultPageSettings]
	 * @memberof Socials
	 */
	static initShareLinks(shareElementNodeOrClass, pageSettings = this.defaultPageSettings, buttonSettings = this.defaultSocialsShareSettings) {
		let shareElements = null;

		if (typeof shareElementNodeOrClass === 'string') {
			shareElements = document.querySelectorAll(shareElementNodeOrClass);
		} else {
			shareElements = shareElementNodeOrClass;
		}

		console.log(shareElements);

		if (shareElements) {
			shareElements.forEach((shareElement) => {
				shareElement.querySelectorAll('.share__link').forEach((link) => {
					const social = this.getSocialItemByName(buttonSettings, link.dataset.mode);
					link.href = this.#getUrl(social, pageSettings);
					if (link.href) {
						link.classList.remove('share__link_disabled');
						link.addEventListener('click', function (event) {
							event.preventDefault();
							window.open(link.href, '', 'toolbar=0,status=0,width=626,height=436');
							// gtag('event', 'index-uverennosti-' + settings[1].mod, {
							// 	event_category: 'Mk-spec',
							// });
						});
					}
				});
			});
		}
	}

	/**
	 * Инициализирует ссылки на страницы социальных сетей
	 *
	 * @static
	 * @param {*} shareElementClass
	 * @param {*} [buttonSettings=this.defaultSocialsSettings]
	 * @memberof Socials
	 */
	static initRedirectLinks(shareElementClass, buttonSettings = this.defaultSocialsSettings) {
		const shareElements = document.querySelectorAll(shareElementClass);
		if (shareElements) {
			shareElements.forEach((shareElement) => {
				shareElement.querySelectorAll(this.settings.shareLinkClass).forEach((link) => {
					link.href = this.getSocialUrlByName(buttonSettings, link.dataset.mode);
					if (link.href) {
						link.classList.remove(this.settings.shareDisabledLinkClass);
					}
				});
			});
		}
	}
}

export default Socials;
