/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { isValidate, isChangePassword, removeBtnLoading, renderBtnLoading } from './../../helpers/index.js';
// eslint-disable-next-line import/no-unresolved
import { errorMessage, answerMessage } from './text-mesages.js';

export default class Form {
	form: HTMLFormElement;
	requiredInputs: NodeListOf<HTMLInputElement>;
	submitBtn: HTMLButtonElement | null;
	requiredInputsLength: number;
	isSignInType: boolean;
	private validateInputList: Set<string>;
	private validateForm: boolean;
	private readonly messageContainer: HTMLParagraphElement | null;
	private readonly typeForm: string;
	private readonly passwordsState: Map<string, string>;
	private readonly passwordConfirm: HTMLInputElement | null;
	private hasSubmitListener: boolean;
	disabledAllActions: null | { (): void };
	enabledAllActions: null | { (): void };
	eyeButtonsList: null | NodeListOf<HTMLButtonElement>;

	constructor(root: HTMLFormElement, typeForm: string) {
		this.form = root;
		this.typeForm = typeForm;
		this.isSignInType = this.typeForm === 'signin';
		this.requiredInputs = this.form?.querySelectorAll('input[required]');
		this.passwordConfirm = this.form.querySelector('[name="password-confirm"]');
		this.submitBtn = this.form?.querySelector('button[type="submit"]');
		this.messageContainer = this.form.querySelector('p.message');

		// helpers
		this.requiredInputsLength = this.requiredInputs.length;
		this.validateInputList = new Set<string>();
		this.validateForm = false;
		this.passwordsState = new Map();
		this.hasSubmitListener = false;
		//this.submitBtn!.disabled = false;

		// callback из родителя
		this.disabledAllActions = null;
		this.enabledAllActions = null;

		// bind слушатели
		this.submitForm = this.submitForm.bind(this);
		this.form.addEventListener('submit', this.submitForm);

		// если есть поля пароля, находим в них "глаза" и вещаем слушатели
		this.eyeButtonsList = null;
		if (this.form.querySelector('[type="password"]')) {
			this.eyeButtonsList = this.getFormEyeButtons();
			this.eyeButtonsList.forEach((button) => button.addEventListener('click', this.showPasswordBtn.bind(this)));
		}

		this.addListeners();
	}

	// вешаем слушатели на инпуты
	addListeners() {
		this.requiredInputs?.forEach((input: HTMLInputElement) => {
			input.addEventListener('input', this.controlRequiredInput.bind(this));
		});
	}

	// Запуск валидации инпутов, вывода ошибки и проверки валидации формы
	controlRequiredInput(data) {
		const input = data.nodeName ? data : data.target;
		const hasValid = this.changeValidType(input);

		this.setStateInput(hasValid, input);
		if (!this.isSignInType) {
			this.isShowMessage(hasValid, input);
		}
		this.checkValidateForm();
	}

	// сравниваем длину required инпутов с количеством валидных инпутов
	matchingRequiredValidedSize() {
		return this.requiredInputsLength === this.validateInputList.size;
	}

	// валидируем пароль и повторите пароль
	isValidatePasswords(input) {
		if (input.name === 'password-confirm') {
			this.passwordsState.set(input.name, input.value);
			return isChangePassword(input.value, this.passwordsState.get('password'));
		} else {
			const passwordConfirm = this.passwordsState.get('password-confirm');
			this.passwordsState.set(input.name, input.value);
			if (passwordConfirm?.length) {
				this.controlRequiredInput(this.passwordConfirm);
			}
			return isValidate(input);
		}
	}

	// валидация для формы авторизации
	isSignInValidate(input) {
		return input.value.length >= 2;
	}

	// валидация для формы регистрации и восстановления пароля
	isSignUpValidate(input) {
		if (this.passwordConfirm) {
			const isPasswordType = input.name.includes('password');
			return isPasswordType ? this.isValidatePasswords(input) : isValidate(input);
		} else {
			return isValidate(input);
		}
	}

	// выбираем какой тип валидации запустить в зависимости от типа формы
	changeValidType(input) {
		if (this.isSignInType) {
			return this.isSignInValidate(input);
		} else {
			return this.isSignUpValidate(input);
		}
	}

	// Снимаем/добавляем ccs стили для инпутов и удаляем/добавляем в Set валидных инпутов
	setStateInput(success, input) {
		if (success) {
			input.classList.remove('error');
			input.classList.add('done');
			this.validateInputList.add(input.name);
		} else {
			input.classList.remove('done');
			input.classList.add('error');
			this.validateInputList.delete(input.name);
		}
	}

	// если инпут прошел валидацию удаляем запускаем удаление ошибки и наоборот (кроме формы авторизации)
	isShowMessage(hasValid, input) {
		if (hasValid) {
			this.resetErrorMessage();
			this.findErrorInputs();
		} else {
			this.getErrorMessageText(input);
		}
	}

	// Если форма валидна вещаем submit и активируем кнопку
	checkValidateForm() {
		if (!this.submitBtn) return;
		this.validateForm = this.matchingRequiredValidedSize();
		if (this.validateForm) {
			//this.submitBtn.disabled = false;
			if (!this.hasSubmitListener) {
				this.form.addEventListener('submit', this.submitForm);
				this.hasSubmitListener = true;
			}
		} else {
			//this.submitBtn.disabled = true;
			if (this.hasSubmitListener) {
				this.form.removeEventListener('submit', this.submitForm);
				this.hasSubmitListener = false;
			}
		}
	}

	// Ищем есть ли в форме еще не валидные инпуты, если есть отправляем на вывод ошибки
	findErrorInputs() {
		const notValidateInput: HTMLInputElement | null = this.form.querySelector('.error');
		if (notValidateInput) {
			this.isShowMessage(false, notValidateInput);
		}
	}

	// принимаем функции из родительского класса
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	sendCallbackDisabledAllActions(fn) {
		this.disabledAllActions = fn;
	}
	sendCallbackEnabledAllActions(fn) {
		this.enabledAllActions = fn;
	}

	// Submit формы
	submitForm(e) {
		e.preventDefault();
		
		if (!this.submitBtn || !this.form) return;

		//this.submitBtn.disabled = false;
		if (this.disabledAllActions) {
			this.disabledAllActions();
		}
		renderBtnLoading(this.submitBtn);
		const formData = new FormData(this.form);
		this.getToken(this.form.action, formData);

	}

	// Проверка токена reCAPTCHA
	getToken(apiUrl, formData) {
		const reCaptchaKey: string | null | undefined = document.querySelector('.authorization-popup')?.getAttribute('data-site');
		const grecaptcha = window.grecaptcha;
		grecaptcha.ready(() => {
			if (reCaptchaKey && grecaptcha) {
				grecaptcha
					.execute(reCaptchaKey, { action: 'submit' })
					.then((token): void => {
						formData.append('token', token);
					})
					.then(() => this.sendPOST(apiUrl, formData));
			}
		});
	}

	// POST запрос
	async sendPOST(url, formData): Promise<void> {
		try {
			const response = await fetch(url, {
				method: 'POST',
				body: formData,
			});
			const result = await response.json();
			this.successfullyResponse(response.status, result);
		} catch (err) {
			console.log('Error ', err);
		} finally {
			removeBtnLoading();
			if (this.enabledAllActions) this.enabledAllActions();
		}
	}

	// работаем с ответом сервера
	successfullyResponse(status, answer) {
		if (status !== 200) {
			this.fillMessageContainer(true, answer.message);
		} else {
			if (this.isSignInType) {
				answer.reload ? location.reload() : null;
			} else {
				this.sendAnswerMessage(this.typeForm);
			}
		}
	}

	// проверка инпутов в случае автозаполнения формы - вроде не нужна, позже удалить!
	// isAutocomplete() {
	// 	const inputs: NodeListOf<HTMLInputElement> = this.form.querySelectorAll(
	// 		'input:not([type="checkbox"])',
	// 	);
	// 	inputs.forEach((input: HTMLInputElement) => {
	// 		if (input.value.length) {
	// 			this.controlRequiredInput(input);
	// 		}
	// 	});
	// }

	// получаем текст ошибки конкретного инпута
	getErrorMessageText(input: HTMLInputElement) {
		const inputName: string = input.name;
		const textError = errorMessage.get(inputName);
		this.fillMessageContainer(true, textError);
	}

	// вывод сообщений в формы "забыли пароль" и "регистрации" в случае 200
	sendAnswerMessage(typeForm) {
		const textError: string = <string>answerMessage.get(typeForm);
		this.fillMessageContainer(false, textError);
	}

	// заполняем текст ошибки
	fillMessageContainer(isError, text) {
		if (this.messageContainer !== text && this.messageContainer) {
			isError ? this.messageContainer.classList.add('message__error') : this.messageContainer.classList.remove('message__error');
			this.messageContainer.innerHTML = text;
		}
	}

	// получаем кнопки "глаза"
	getFormEyeButtons(): NodeListOf<HTMLButtonElement> {
		return this.form.querySelectorAll('input + button');
	}

	// показываем/скрываем пароль и меняем иконку глаза
	showPasswordBtn({ target }) {
		const input = target.previousElementSibling;
		const svg = target.querySelector('use');
		if (input.type === 'password') {
			input.type = 'text';
			// eslint-disable-next-line sonarjs/no-duplicate-string
			svg.setAttribute('xlink:href', '#open-eye');
		} else {
			input.type = 'password';
			svg.setAttribute('xlink:href', '#close-eye');
		}
		input.focus();
	}

	// удаляем текст ошибки
	resetErrorMessage() {
		this.fillMessageContainer(false, '');
	}

	// чистим форму
	clearForm() {
		this.form.reset();
	}

	// закрываем форму
	close() {
		this.form.classList.add('form-disable');
		this.resetErrorMessage();
	}

	// открываем форму
	open() {
		this.form.classList.remove('form-disable');
	}

	// сбрасываем все css стили, скрываем пароли и меняем икноку глаза
	reset() {
		this.requiredInputs.forEach((input: HTMLInputElement) => {
			input.classList.remove('done');
			input.classList.remove('error');
		});
		if (this.eyeButtonsList?.length) {
			this.eyeButtonsList.forEach((button: HTMLButtonElement) => {
				button.querySelector('use')?.setAttribute('xlink:href', '#close-eye');
				const input = <HTMLInputElement>button.previousElementSibling;
				if (input) input.type = 'password';
			});
		}
	}

	// сброс для формы восстановления пароля
	resetRemember() {
		this.submitBtn!.style.display = 'block';
		const text = 'Пожалуйста, укажите email, который Вы использовали для входа в личный кабинет. Вам будет отправлено сообщение со ссылкой для создания нового пароля.';
		this.fillMessageContainer(false, text);
	}
}
