import Carousel from './carousel';

class FactoryCarousel {
	constructor(className) {
		this.articles = document.querySelectorAll(className);
	}

	init() {
		if (this.articles) {
			this.articles.forEach((item) => {
				new Carousel(item, { container: 'articles-slider', item: 'articles-slider__slide', isInfinityScroll: false });
			});
		}
	}
}

export default FactoryCarousel;
