/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { removeBtnLoading, renderBtnLoading } from '../../helpers';

class Polls {
	constructor() {
		this.key = '';
		this.forms = [];
		this.submitObjects = [];
		this.init();
	}

	async init() {
		const isLoad = await this.onLoadPage();
		if (isLoad) {
			this.forms = document.querySelectorAll('form.poll__form');
			this.submitObjects = this.#mapSubmitObjects();
			this.#setupEventHandlers();
		}
	}

	/**
	 * Перебирает массив форм и возращает массив объектов с ключём, кнопкой, формой;
	 *
	 * @memberof Polls
	 */
	#mapSubmitObjects() {
		return Array.from(this.forms).map((form) => {
			const button = form.querySelector('button[type=submit]');
			return { button, form, key: form.getAttribute('data-key') };
		});
	}

	/**
	 * Устанавливает события для кнопок отправки формы;
	 *
	 * @memberof Polls
	 */
	#setupEventHandlers() {
		this.submitObjects.forEach((item) => {
			item.form.removeAttribute('data-key');
			item.button.addEventListener('click', (event) => {
				event.preventDefault();
				this.submitForm(item.form, item.key, event.target);
			});
		});
	}

	/**
	 * Находит элемент section с data-id равным pollId и заменяет его на указанный HTML.
	 *
	 * @param {number} pollId - ID опроса для поиска элемента
	 * @param {string} html - HTML-код для замены
	 * @memberof Polls
	 */
	#replacePollElement(pollId, data) {
		const selectors = {
			view: `.single-poll_view[data-id='${pollId}']`,
			aside: `.single-poll_aside .poll.poll_single[data-id='${pollId}']`,
			list: `.poll.polls__item[data-id='${pollId}']`,
		};

		for (const [key, html] of Object.entries(data)) {
			if (!selectors[key]) {
				continue;
			}

			const parser = new DOMParser();
			const doc = parser.parseFromString(html, 'text/html');
			const replaceElement = key === 'list' ? doc.body.innerHTML : doc.body.firstChild;
			const className = selectors[key];
			const targetElement = document.querySelector(className);

			if (targetElement) {
				targetElement.outerHTML = replaceElement.outerHTML || replaceElement;
			}
		}
	}

	/**
	 * Отправляет результаты голования;
	 *
	 * @param {*} form - форма голосования
	 * @param {*} key - ключ авторизации api
	 * @param {*} target - кнопка submit
	 * @memberof Polls
	 */
	async submitForm(form, key, target) {
		const selectedRadioButton = form.querySelector('input[name="poll"]:checked');
		const radioButtons = form.querySelectorAll('input[name="poll"]');

		if (selectedRadioButton) {
			renderBtnLoading(target);

			radioButtons.forEach((radio) => {
				radio.disabled = true;
			});

			const answerId = selectedRadioButton.getAttribute('data-key');
			const postData = { answerId };

			try {
				const response = await fetch(form.action, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: key,
					},
					body: JSON.stringify(postData),
				});

				if (!response.ok) {
					throw new Error('[js] Error send form');
				}

				const errorMessage = target.nextSibling;
				if (errorMessage && errorMessage.tagName === 'P') {
					errorMessage.parentNode.removeChild(errorMessage);
				}

				const data = await response.json();

				this.#replacePollElement(data.pollId, { view: data.view, list: data.list, aside: data.aside });
			} catch (error) {
				let errorMessage = target.nextSibling;

				if ((errorMessage && errorMessage.tagName != 'P') || errorMessage === null) {
					errorMessage = document.createElement('p');

					errorMessage.textContent = 'Что-то пошло не так. Попробуйте позже.';

					target.parentNode.insertBefore(errorMessage, target.nextSibling);
				}
			} finally {
				removeBtnLoading();
				radioButtons.forEach((radio) => {
					radio.disabled = false;
				});
			}
		}
	}

	async onLoadPage() {
		const isActiveVote = document.querySelectorAll('.poll-loader[data-ajax]');

		try {
			if (!isActiveVote || isActiveVote.length === 0) {
				console.info('[js] Not found active vote');
			} else {
				const response = await fetch(isActiveVote[0].dataset.action, {
					method: 'GET',
				});

				if (!response.ok) {
					throw new Error('[js] Error sending request');
				}

				const polls = await response.json();

				isActiveVote.forEach((item) => {
					const pollId = item.dataset.id;

					if (polls?.polls[pollId]) {
						this.#replacePollElement(pollId, polls?.polls[pollId]);
					} else {
						console.error(`[js] Poll data not found for id: ${pollId}`);
					}
				});
				return true;
			}
		} catch (e) {
			console.warn(e);
		}

		return false;
	}
}

new Polls();
