import { swapSrcPaths } from '../../helpers/index';

class LazyLoaderGoogs {
	constructor(selector) {
		this.scriptList = document.head.querySelectorAll(`${selector}`);
		this.lazyListener = this.lazyListener.bind(this);
	}

	init() {
		if (this.scriptList) {
			this.addStateListeners();
		}
	}

	lazyListener() {
		this.scriptList.forEach((script) => {
			swapSrcPaths(script);
		});

		this.removeStateListeners();
	}

	addStateListeners() {
		['mouseover', 'click', 'scroll'].forEach((event) => {
			const target = event === 'click' ? document.getElementsByTagName('body')[0] : window;

			target.addEventListener(event, this.lazyListener);
		});
	}

	removeStateListeners() {
		['mouseover', 'click', 'scroll'].forEach((event) => {
			const target = event === 'click' ? document.getElementsByTagName('body')[0] : window;

			target.removeEventListener(event, this.lazyListener);
		});
	}
}

export default LazyLoaderGoogs;
