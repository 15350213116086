import '../styles/styl.styl';

// Подключение модулей
import Header from './modules/header/header.js';
import Socials from './modules/socials';
import './modules/polls';
import './modules/sliderPublicationsLoader';

/**
 * Change log #0024092024
 */
// import './services/sponsor-top';

import Lazyloading from './services/lazy-loading/lazyLoading';
import LazyLoaderGoogs from './services/lazy-loading/lazyLoadingGoods';

// import CategorySwitcher from './services/category-switcher';

import FactoryCarousel from './services/carousel/factoryCarousel';

// Автономные модули
import './modules/auth/index.js';

class Main {
	/**
	 * Инициализация динамической загрузки скриптов для различных страниц
	 *
	 * @memberof Main
	 */
	async #dynamicBundleLoader() {
		const pageType = document.querySelector('.grid-container');

		try {
			switch (pageType?.dataset?.page) {
				case 'main': {
					console.info('[location] index');
					break;
				}

				case 'publication': {
					console.info('[location] publication');

					await import('./modules/publication');

					break;
				}

				case 'profile': {
					console.info('[location] profile');

					await import('./modules/profile');

					break;
				}

				case 'listing':
				case 'polls':
				case 'search': {
					console.info('[location] listing / polls / search');

					await import('./modules/listing');

					break;
				}

				default:
					console.warn('[location] unknown page');
			}
		} catch (error) {
			console.error('Error loading module:', error);
		}
	}

	/**
	 * Инициализация JavaScript компонентов
	 *
	 * @memberof Main
	 */
	init() {
		// Ленивая загрузка КАРТИНОК
		new Lazyloading('img[loading=lazy]').init();

		// Ленивая загрузка РЕКЛАМЫ
		new LazyLoaderGoogs('script[data-src]').init();

		// Переключатель категорий
		// new CategorySwitcher();

		// Инициализация обычных каруселей
		new FactoryCarousel('.articles').init();

		// Скрипт навигации в шапке
		new Header('.header');

		// Инициализация ссылок на социальные сети
		Socials.initRedirectLinks('.socials-posting');

		// Инициализация ссылок поделиться
		Socials.initShareLinks('.socials-sharing');

		// Динамическое подключение модулей
		this.#dynamicBundleLoader();
	}
}

new Main().init();
