import regAuth from '../../services/authorization-popup/AuthorizationPopup';

const loginBtnsContainers = document.querySelectorAll('.ring, .nav, .comments');
const authReg = document.querySelector('.authorization-popup');
const userPopup = document.querySelector('.user-popup');
const profileBtn = userPopup ? document.querySelector('.ring__right-btn') : null;

// Закрываем попап авторизованого юзера(на мобильной версии место попапа используется details в навигации)
function closeUserPopup({ currentTarget }) {
	if (!(currentTarget === profileBtn || currentTarget.closest('.user-popup'))) {
		//userPopup.classList.remove('enable');
		document.removeEventListener('click', closeUserPopup);
	}
}

// Открываем попап авторизованого юзера (на мобильной версии место попапа используется details в навигации)
function controlUserPopup() {
	if (userPopup.matches('.enable')) {
		//userPopup.classList.remove('enable');
		document.removeEventListener('click', closeUserPopup);
	} else {
		//userPopup.classList.add('enable');
		document.addEventListener('click', closeUserPopup);
	}
}

if (loginBtnsContainers.length) {
	// Проверяем наличие в DOM либо попапа для авторизации или попала информации о пользователе. Наличие их зависит от параметра isAuthorized из php
	if (authReg) {
		new regAuth(authReg, loginBtnsContainers);
	} else if (userPopup) {
		profileBtn.addEventListener('click', controlUserPopup);
	}
}
