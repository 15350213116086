export default function () {
	const header = document.querySelector('.header');
	const ring = document.querySelector('.ring');

	const headerObserve = new IntersectionObserver(([entry]) => {
		header.classList.toggle('header_fixed', !entry.isIntersecting);
		ring.style.transform = `translate(0, ${entry.isIntersecting ? '0px' : '-25px'})`;
	});

	if (ring) headerObserve.observe(ring);
}
