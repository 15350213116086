const errorMessage = new Map([
	['email', 'В указанном E-mail допущена ошибка. Проверьте правильность заполнения поля'],
	['password', 'Пароль должен содержать только латинские буквы, не меньше одной заглавной и строчной, не меньше одной цифры, в количестве не менее 8 символов'],
	['password-confirm', 'Поля “Пароль” и “Повторите пароль” не совпадают. Проверьте правильность заполнение полей'],
	['nickname', 'Поле является обязательным к заполеннию.'],
]);

const answerMessage = new Map([
	['signup', '<span>Вам на почту выслана ссылка для подтверждения email.</span><br/><br/>Не забудьте проверить папку "Спам"'],
	['remember', 'На указанный E-mail отправлена ссылка для восстановления доступа.<br/><br/>Пожалуйста, дождитесь письма, т.к. при каждом новом запросе ссылка изменяется.<br/><br/>Если письмо так и не пришло, рекомендуем проверить спам или попытаться ещё раз восстановить доступ.'],
]);

export { errorMessage, answerMessage };
