const cloneNodeWithoutContent = function (node, tagName) {
	const TAG_NAME = tagName === undefined ? node.tagName.toLowerCase() : tagName;
	const clone = document.createElement(TAG_NAME);
	for (let i = 0; i < node.attributes.length; i++) {
		clone.setAttribute(node.attributes[i].name, node.attributes[i].value);
	}
	return clone;
};

const fillElementFromStringUnsafe = function (markupString, node) {
	const RX = new RegExp('<script.*?>([^]*?)</script>', 'g');
	let res = RX.exec(markupString);
	node.innerHTML = markupString;

	const scripts = node.querySelectorAll('script');
	scripts.forEach((scriptNode) => {
		scriptNode.innerText = '';
		const newScriptNode = cloneNodeWithoutContent(scriptNode);
		newScriptNode.appendChild(document.createTextNode(res[1]));
		scriptNode.parentElement.replaceChild(newScriptNode, scriptNode);
		res = RX.exec(markupString);
	});

	return node;
};

const createElementFromStringUnsafe = function (markupString, tagName = 'div') {
	const node = document.createElement(tagName);
	return fillElementFromStringUnsafe(markupString, node);
};

/**
 * Функция проверяет соответсвие двух строк (паролей)
 *
 * @param {*} element
 */
const isChangePassword = (password1, password2) => password1 === password2;

/**
 * Функция замены src на значение data-src.
 * Также удаляет аттрибут loading и data-src после замены.
 *
 * @param {*} element
 */
const swapSrcPaths = function (element) {
	const { dataset, tagName } = element;

	if (dataset?.src) {
		element.src = dataset.src;
		element.removeAttribute('data-src');

		if (tagName === 'IMG') {
			element.removeAttribute('loading');
		}
	}
};

/**
 * Функция валидации полей ввода данных
 *
 * @param {*} input
 */
const isValidate = function (input) {
	const value = input.value;
	const name = input.name;
	if (name === 'email') {
		return /(?=^.{5,320}$)^([a-z\d_-]+\.)*[a-z\d_-]+@[a-z\d_-]+(\.[a-z\d_-]+)*\.[a-z]{2,6}$/.test(value);
	} else if (name === 'password') {
		return /(?=^.{8,128}$)^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$/.test(value);
	} else if (name === 'nickname') {
		return value ? true : false;
	} else {
		return false;
	}
};

/**
 * Создание анимированного SVG элемента внутри кнопки.
 *
 * @param {*} btn
 */
const renderBtnLoading = function (btn) {
	btn.disabled = true;

	btn.classList.add('btn-loader');

	btn.insertAdjacentHTML(
		'afterbegin',
		'<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-dasharray="15" stroke-dashoffset="15" stroke-linecap="round" stroke-width="2" d="M12 3C16.9706 3 21 7.02944 21 12"><animate fill="freeze" attributeName="stroke-dashoffset" dur="0.3s" values="15;0"/><animateTransform attributeName="transform" dur="1.5s" repeatCount="indefinite" type="rotate" values="0 12 12;360 12 12"/></path></svg>',
	);
};

/**
 * Удаление анимированного SVG элемента внутри кнопки.
 *
 * @param {*} btn
 */
const removeBtnLoading = function () {
	const loaderForBtns = document.querySelectorAll('.btn-loader');
	if (loaderForBtns) {
		loaderForBtns.forEach((loader) => {
			const svgElement = loader.querySelector('svg');

			if (svgElement) {
				svgElement.remove();
			}

			loader.classList.remove('btn-loader');
			loader.disabled = false;
		});
	}
};

export { fillElementFromStringUnsafe, createElementFromStringUnsafe, swapSrcPaths, isValidate, renderBtnLoading, removeBtnLoading, isChangePassword };

//const getRandomIntFromInterval = function (min, max) {
//	return Math.floor(Math.random() * (max + 1 - min) + min);
//};

//const toShaffleArray = function (arr, n = arr.length) {
//	const count = arr.length > n ? n : arr.length;
//	for (let i = 0; i < count; i++) {
//		const randomPosition = getRandomIntFromInterval(0, arr.length - 1);
//		[arr[i], arr[randomPosition]] = [arr[randomPosition], arr[i]];
//	}
//	return arr;
//};

//const getNthRandomFromArray = function (n, arr) {
//	const count = arr.length > n ? n : arr.length;
//	return toShaffleArray(arr, count).slice(0, count);
//};

//const getNthRandomFromInterval = function (n, min, max) {
//	const tmpArray = [];
//	const count = max - min + 1 > n ? n : max - min + 1;
//	for (let i = 0; i < count; i++) {
//		const randomPosition = getRandomIntFromInterval(min, max);
//		[tmpArray[i], tmpArray[randomPosition - min]] = [tmpArray[randomPosition - min] === undefined ? randomPosition : tmpArray[randomPosition - min], tmpArray[i] === undefined ? i + min : tmpArray[i]];
//	}
//	return tmpArray.slice(0, count);
//};

//const makeElementFromString = function (elementString) {
//	const newElement = document.createElement('div');
//	newElement.innerHTML = elementString.trim();
//	return newElement.firstChild;
//};

//const createElementFromArray = function (elementsArray, root) {
//	const element = elementsArray
//		.reduce(function (a, b) {
//			return a.concat(b);
//		}, [])
//		.join('')
//		.trim();

//	root.innerHTML = element;
//};

//const checkStatus = function (response) {
//	if (response.status >= 200 && response.status < 300) {
//		return response;
//	} else {
//		throw new Error(`${response.status}: ${response.statusText}`);
//	}
//};

//const toJSON = function (response) {
//	return response.json();
//};

//const getCookie = function (name) {
//	let matches = document.cookie.match(new RegExp('(?:^|; )' + name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1') + '=([^;]*)'));
//	return matches ? decodeURIComponent(matches[1]) : undefined;
//};

//const setCookie = function (name, value, options = {}) {
//	options = {
//		path: '/',
//		...options,
//	};

//	if (options.expires instanceof Date) {
//		options.expires = options.expires.toUTCString();
//	}

//	let updatedCookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;

//	for (let optionKey in options) {
//		updatedCookie += `; ${optionKey}`;
//		let optionValue = options[optionKey];
//		if (optionValue !== true) {
//			updatedCookie += `=${optionValue}`;
//		}
//	}

//	document.cookie = updatedCookie;
//};

//const deleteCookie = function (name) {
//	setCookie(name, '', {
//		'max-age': -1,
//	});
//};

//const articlePreviewsAdapter = function (data) {
//	const adapElement = function (dataElement) {
//		return {
//			title: dataElement.title,
//			picture: {
//				src: dataElement.teaserImage,
//				srcLarge: dataElement.detailImage,
//				alt: dataElement.imageTitle,
//			},
//			coverPicture: [
//				{ src: dataElement.teaserImage, size: 90 },
//				{ src: dataElement.announceImage, size: 210 },
//				{ src: dataElement.detailImage, size: 550 },
//			],
//			url: dataElement.url,
//			meta: {
//				exclusive: Boolean(dataElement.isExclusive),
//				photo: Boolean(dataElement.hasPhotos),
//				video: Boolean(dataElement.hasVideos),
//				views: dataElement.viewsCount,
//			},
//		};
//	};

//	let articles = [];
//	data.articles.forEach((article) => {
//		articles.push(adapElement(article));
//	});

//	return articles;
//};

//const newsPreviewsAdapter = function (data) {
//	const adapElement = function (dataElement) {
//		return {
//			title: dataElement.title,
//			url: dataElement.url,
//			time: dataElement.time,
//			region: Boolean(dataElement.regionId),
//			hot: Boolean(dataElement.isImportant),
//		};
//	};

//	let news = [];
//	data.news.forEach((article) => {
//		news.push(adapElement(article));
//	});

//	return news;
//};

//const pollsPreviewsAdapter = function (data) {
//	const adapElement = function (dataElement) {
//		return {
//			id: dataElement.id,
//			title: dataElement.title || '',
//			url: dataElement.url || '',
//			description: dataElement.description || '',
//			regionId: Boolean(dataElement.regionId),
//			answerOptions: dataElement.answerOptions.map((answer) => {
//				return {
//					id: answer.id,
//					name: answer.name,
//					votesPercent: answer.votesPercent,
//				};
//			}),
//		};
//	};

//	let polls = [];
//	data.forEach((poll) => {
//		polls.push(adapElement(poll));
//	});

//	return polls;
//};

//const extractParsedObjects = function (node, adapter) {
//	if (node.textContent) {
//		return adapter(JSON.parse(node.textContent));
//	}
//};

//const getLocation = function (href) {
//	const location = document.createElement('a');
//	location.href = href;
//	return location;
//};

//const printNumWithLead0 = (number) => (number < 10 ? `0${number}` : number);

//const blockElements = function (elementsArray) {
//	elementsArray.forEach((element) => element.setAttribute('disabled', 'disabled')); // в utils
//};

//const unBlockElements = function (elementsArray) {
//	elementsArray.forEach((element) => element.removeAttribute('disabled'));
//};

//const getDataFromNode = function (selector, defaultAnswer = null) {
//	const dataNode = document.querySelector(selector);
//	return dataNode === null ? defaultAnswer : JSON.parse(dataNode.innerText);
//};
