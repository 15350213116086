import { swapSrcPaths } from '../../helpers/index';

class Lazyloading {
	constructor(selector, config = { root: null, rootMargin: '50px 0px 0px', threshold: 0.1 }) {
		this.lazyImages = [].slice.call(document.querySelectorAll(selector));
		this.options = { ...config };
	}

	/**
	 * Инициализация ленивой загрузки
	 *
	 * @memberof Lazyloading
	 */
	init() {
		if ('IntersectionObserver' in window) {
			const callback = (entries) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						let lazyImage = entry.target;
						swapSrcPaths(lazyImage);
						this.lazyImageObserver.unobserve(lazyImage);
					}
				});
			};
			this.lazyImageObserver = new IntersectionObserver(callback, this.options);
			this.lazyImages.forEach((image) => this.lazyImageObserver.observe(image));
		}
	}
}

export default Lazyloading;
