/* eslint-disable prettier/prettier */
import CarouselChanger from './carousel-changer.js';

const DEFAULT_SETTINGS = {
	// Включить если нужен счетчик в загаловке
	isCounter: false,
	counter: 'aside-head__counter',

	// Управление стрелками
	backButton: 'arrow__button_prev',
	nextButton: 'arrow__button_next',

	// Объявление контенера и его элементов
	container: 'carousel__cards',
	item: 'carousel__card',

	// Включить если есть точки
	isDots: false,
	dots: 'dots',
	dotsButton: 'dot',
	dotsButtonActive: 'dot_active',
	// preview ?

	isPreview: false,
	showPreview: false,

	// Хз что тут
	stepCoefficient: 1,
	gap: 0,
	isInfinityScroll: false,
};

class Carousel {
	constructor(root, settingAttrs = DEFAULT_SETTINGS) {
		this.settings = Object.assign({}, DEFAULT_SETTINGS, settingAttrs);
		this.root = root;

		this.dotsContainer = root.querySelector(`.${this.settings.dots}`);
		this.itemsElements = root.querySelectorAll(`.${this.settings.item}`);
		this.dotsElements = root.querySelectorAll(`.${this.settings.dotsButton}`);
		this.counter = this.root.querySelector(`.${this.settings.counter}`);
		this.isPreview = this.settings.isPreview;
		this.dotsElementsActiveIndex = 0;
		this.isAnimating = false;

		this.container = root.querySelector(`.${this.settings.container}`);
		this._stepSize = null;

		this.initArrowButton();

		// Запускаем если есть текстовый счетчик и точки
		if (this.settings.isCounter && !this.isPreview) {
			this.initCounter(this.counter, this.itemsElements, 0);
		}
		if (this.settings.isCounter && this.isPreview) {
			this.counter.innerText = `0/${this.itemsElements.length}`;
		}
		// Запускаем если есть только точки
		if (this.settings.isDots) {
			this.initCounter(null, this.itemsElements, this.startCount);
		}
		window.onresize = () => {
			this._stepSize = null;
		};
		this.createDots();
	}

	// Создаем количество точек относительно каличества слайдов
	createDots() {
		// Если в контейнере нет точек, то создаем их тут
		if (this.dotsContainer && !this.dotsElements.length) {
			this.itemsElements.forEach(() => {
				this.dotsContainer.insertAdjacentHTML('beforeend', '<button class="dot" aria-label="перейти к слайду"></button>');
			});

			this.dotsElements = this.dotsContainer.querySelectorAll(`.${this.settings.dotsButton}`);

			this.init();
		}
		// Если точки созданы в ручную, то запускается эта часть
		else if (this.dotsContainer && this.dotsElements.length) {
			this.init();
		}
	}

	init() {
		this.dotsElements.forEach((dot, i) => {
			const dotComponent = new CarouselChanger(dot, this, i);
			dotComponent.initButtons();
		});
	}

	initArrowButton() {
		this.backButton = this.root.querySelector(`.${this.settings.backButton}`);
		this.nextButton = this.root.querySelector(`.${this.settings.nextButton}`);

		if (this.backButton && this.nextButton) {
			this.backButton.addEventListener('click', this.onBackButtonClick().bind(this));

			if (!this.isPreview) {
				this.nextButton.addEventListener('click', this.onNextButtonClick().bind(this));
			}
		}
	}

	initCounter(counter = null, nodes, startCount) {
		const length = nodes.length;
		// eslint-disable-next-line sonarjs/cognitive-complexity
		nodes.forEach((node, index) => {
			const observer = new IntersectionObserver(
				(entries) => {
					if (entries[0].isIntersecting) {
						if (counter !== null) {
							if (index === 0) {
								this.backButton.disabled = true;
							} else if (index === length - 1) {
								this.nextButton.disabled = true;
							} else {
								this.nextButton.disabled = false;
								this.backButton.disabled = false;
							}
							counter.innerText = `${index + startCount}/${length}`;
							this.controlActiveDots(index);
							if (this.controlActiveDash) {
								this.controlActiveDash(index);
							}
							if (this.settings.figcaption) {
								this.changeFigcaption(entries[0].target);
							}
						} else {
							this.controlActiveDots(index);
							if (this.controlActiveDash) {
								this.controlActiveDash(index);
							}
						}
					}
				},
				{ threshold: 0.5 },
			);
			observer.observe(node);
		});
	}

	get stepSize() {
		if (this._stepSize === null) {
			this._stepSize = this.root.querySelector(`.${this.settings.item}`).offsetWidth * this.settings.stepCoefficient + this.settings.gap;
		}
		return this._stepSize;
	}

	// Отрисовка активной точки
	controlActiveDots(number) {
		this.dotsElements[this.dotsElementsActiveIndex].classList.remove(`${this.settings.dotsButtonActive}`);
		this.dotsElementsActiveIndex = number;
		this.dotsElements[number].classList.add(`${this.settings.dotsButtonActive}`);
	}

	// Пролистывание слайда с клика по точкам
	goTo(number) {
		if (this.dotsElementsActiveIndex !== number) {
			this.controlActiveDots(number);
			if (this.controlActiveDash) {
				this.controlActiveDash(number);
			}
			if ('scrollBehavior' in this.container.style) {
				this.container.scrollTo(this.stepSize * number, 0);
			} else {
				this.scroll(this.stepSize * number - this.container.scrollLeft, 200);
			}
		}
	}

	scroll(delta, animationTransition) {
		const start = this.container.scrollLeft;
		let startTime = null;
		const container = this.container;

		const step = function (lastTime) {
			if (startTime === null) {
				startTime = lastTime;
			}
			const calcProgress = Math.abs(startTime - lastTime) / animationTransition;
			const progress = calcProgress < 1 ? calcProgress : 1;
			container.scrollTo(start + delta * progress, 0);
			if (progress < 1) {
				requestAnimationFrame(step);
			}
		};
		requestAnimationFrame(step);
	}

	onNextButtonClick() {
		return 'scrollBehavior' in this.container.style
			? () => {
					if (this.isAnimating === false) {
						this.container.scrollLeft + this.container.offsetWidth === this.container.scrollWidth && this.settings.isInfinityScroll ? this.container.scrollTo(0, 0) : this.container.scrollTo(this.container.scrollLeft + this.stepSize, 0);
						setTimeout(() => {
							this.isAnimating = false;
						}, 450);
						this.isAnimating = true;
					}
				}
			: () => {
					if (this.isAnimating === false) {
						this.container.scrollLeft + this.container.offsetWidth === this.container.scrollWidth && this.settings.isInfinityScroll ? this.scroll(-this.container.scrollLeft, 200) : this.scroll(this.stepSize, 200);
						setTimeout(() => {
							this.isAnimating = false;
						}, 450);
						this.isAnimating = true;
					}
				};
	}

	onBackButtonClick() {
		return 'scrollBehavior' in this.container.style
			? () => {
					if (this.isAnimating === false) {
						this.container.scrollLeft === 0 && this.settings.isInfinityScroll ? this.container.scrollTo(this.container.scrollWidth, 0) : this.container.scrollTo(this.container.scrollLeft - this.stepSize, 0);
						setTimeout(() => {
							this.isAnimating = false;
						}, 500);
						this.isAnimating = true;
					}
				}
			: () => {
					if (this.isAnimating === false) {
						this.container.scrollLeft === 0 && this.settings.isInfinityScroll ? this.scroll(this.container.scrollWidth - this.stepSize, 200) : this.scroll(-this.stepSize, 200);
						setTimeout(() => {
							this.isAnimating = false;
						}, 500);
						this.isAnimating = true;
					}
				};
	}
}

export default Carousel;
