import getWindowWidth from '../../helpers/getWindowWidth.js';
import headerPosition from '../header/header-scroll.js';

class Header {
	constructor(root) {
		this.root = document.querySelector(root);
		if (this.root) {
			this.body = document.querySelector('body');
			this.burgerBtn = this.root.querySelector('.header__burger');
			this.nav = this.root.querySelector('.nav');
			// eslint-disable-next-line sonarjs/no-duplicate-string
			this.navItems = this.nav.querySelectorAll('.nav__rubric');
			this.windowWidth = getWindowWidth();

			this.burgerBtn.addEventListener('click', this.showNav.bind(this));

			headerPosition();
		}
	}

	showNav() {
		if (this.burgerBtn.matches('.active')) {
			this.burgerBtn.ariaLabel = 'Открыть меню';
		} else {
			this.burgerBtn.ariaLabel = 'Закрыть меню';
			this.root.scrollIntoView();
			this.openNav();
		}
		this.burgerBtn.classList.toggle('active');
		this.root.classList.toggle('show-nav');
		this.nav.classList.toggle('active');
		this.body.classList.toggle('no-scroll');
	}

	openNav() {
		this.removeCssClass(this.navItems);

		if (this.windowWidth >= 368) this.navItems[0].classList.add('active');

		this.nav.addEventListener('click', this.navControl.bind(this));
	}

	removeCssClass(list, className = 'active') {
		list.forEach((el) => el.classList.remove(className));
	}

	navControl({ target }) {
		if (target === this.nav) {
			target.classList.remove('active');
			this.burgerBtn.ariaLabel = 'Открыть меню';
			this.burgerBtn.classList.remove('active');
			this.body.classList.remove('no-scroll');
		}
		if (target.closest('.nav__rubric') && !target.matches('a')) {
			this.removeCssClass(this.navItems);
			target.closest('.nav__rubric').classList.add('active');
		}
	}
}

export default Header;
