const DEFAULT_SETTINGS = {
	item: 'card',
	button: 'dot',
	buttonActive: 'dot_active',
	activeItemMod: 'slider__slide-changer-item_active',
};

class CarouselChanger {
	constructor(root = null, sliderComponent, index, settingAttrs = DEFAULT_SETTINGS) {
		this.settings = Object.assign({}, DEFAULT_SETTINGS, settingAttrs);
		this.dotElement = root;
		this.sliderComponent = sliderComponent;
		this.index = index;
	}

	initButtons() {
		this.dotElement.addEventListener('click', () => {
			this.sliderComponent.goTo(this.index);
			if (this.sliderComponent.isPreview) {
				this.sliderComponent.controlAboutBlock();
			}
		});
	}
}

export default CarouselChanger;
