/**
 * Возвращает текущую ширину области просмотра
 *
 * @return {*}
 */
export default function () {
	let windowWidth = window.innerWidth;

	window.addEventListener('resize', () => {
		windowWidth = window.innerWidth;
	});

	return windowWidth;
}
