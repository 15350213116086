import { swapSrcPaths } from './../../helpers/index.js';
import Form from './Form';

export default class AuthorizationPopup {
	root: HTMLElement;
	btnsControlContainers: NodeListOf<HTMLElement>;
	container: HTMLElement | null;
	closeBtn: HTMLElement | null;
	body: HTMLElement | null;
	radioContainer: HTMLElement | null;
	radioBtnsList: NodeListOf<HTMLInputElement> | undefined;
	signinFormElement: HTMLFormElement | null;
	signupFormElement: HTMLFormElement | null;
	rememberFormElement: HTMLFormElement | null;
	showForgetForm: HTMLElement | undefined | null;
	token: string;
	Signin: Form | null;
	Signup: Form | null;
	Remember: Form | null;
	formDisableCss: string;

	constructor(root: HTMLElement, btnsControlContainers: NodeListOf<HTMLElement>) {
		this.body = document.querySelector('body');
		this.root = root;
		this.btnsControlContainers = btnsControlContainers;
		this.container = this.root.querySelector('.authorization-popup__container');
		this.closeBtn = this.root.querySelector('.authorization-popup__button');
		this.radioContainer = this.root.querySelector('.authorization-popup__radio');
		this.radioBtnsList = this.radioContainer?.querySelectorAll('input[type="radio"]');
		this.signinFormElement = this.root.querySelector('[data-form="signin"]');
		this.signupFormElement = this.root.querySelector('[data-form="signup"]');
		this.rememberFormElement = this.root.querySelector('[data-form="remember"]');
		this.showForgetForm = this.signinFormElement?.querySelector('.signin-form__forget');
		this.token = '';
		this.formDisableCss = 'form-disable';

		// bind слушатели
		this.closePopup = this.closePopup.bind(this);
		this.escBtnListener = this.escBtnListener.bind(this);

		this.Signin = null;
		this.Signup = null;
		this.Remember = null;

		this.btnsControlContainers.forEach((container) => container?.addEventListener('click', this.checkButton.bind(this)));
	}

	initFormCallback(form) {
		form.sendCallbackDisabledAllActions(this.disabledAllActions.bind(this));
		form.sendCallbackEnabledAllActions(this.enabledAllActions.bind(this));
	}

	// Запускаем сторонние скрипты: капча
	runThirdPartyScripts() {
		this.root.querySelectorAll('script').forEach((script) => {
			if (script.hasAttribute('data-src')) {
				swapSrcPaths(script);
			}
		});
	}

	// Проверяем был ли клик на нужной кнопке & init forms
	checkButton({ target }) {
		const typeForm = target.dataset.authorized;
		if (typeForm) {
			if (this.signinFormElement && !this.Signin) {
				this.Signin = new Form(this.signinFormElement, <string>this.signinFormElement.dataset.form);
				this.initFormCallback(this.Signin);
			}
			if (this.signupFormElement && !this.Signup) {
				this.Signup = new Form(this.signupFormElement, <string>this.signupFormElement.dataset.form);
				this.initFormCallback(this.Signup);
			}
			if (this.rememberFormElement && !this.Remember) {
				this.Remember = new Form(this.rememberFormElement, <string>this.rememberFormElement.dataset.form);
				this.initFormCallback(this.Remember);
			}
			this.openPopup(typeForm);
		}
	}

	// Открываем попап вещаем слушатели блокируем скролл
	openPopup(typeForm) {
		this.runThirdPartyScripts();
		this.root.classList.remove(this.formDisableCss);
		this.body?.classList.add('no-scroll');
		this.closeBtn?.addEventListener('click', this.closePopup);
		document.addEventListener('keydown', this.escBtnListener);
		this.startedCheckedRadio(typeForm);
		this.radioBtnsList?.forEach((radioBtn: HTMLInputElement) => {
			radioBtn.addEventListener('click', this.radioControlsBtn.bind(this));
		});
		this.showForgetForm?.addEventListener('click', this.openForgetForm.bind(this));
	}

	// закрываем формы авторизации и регистрации, открываем форму восстановления, прям панель с радио кнопками
	openForgetForm() {
		this.Signin?.close();
		this.Signup?.close();
		this.Remember?.open();
		this.radioContainer?.classList.add(this.formDisableCss);
	}

	// Закрываем попап и снимаем слушатели, запускаем сброс
	closePopup() {
		this.Signin?.clearForm();
		this.Signup?.clearForm();
		this.resetForgetForm();
		this.root.classList.add(this.formDisableCss);
		this.body?.classList.remove('no-scroll');
		this.closeBtn?.removeEventListener('click', this.closePopup);
		document.removeEventListener('keydown', this.escBtnListener);
		this.resetAll();
	}

	// Закрываем попап по кнопке ESC
	escBtnListener({ code }) {
		if (code === 'Escape') {
			this.closePopup();
		}
	}

	// Определяем какую из форм при первом открытии увидит пользователь
	startedCheckedRadio(typeForm) {
		const activeRadioForm: number = typeForm === 'signin' ? 0 : 1;
		if (this.radioBtnsList) {
			this.radioBtnsList[activeRadioForm].checked = true;
			this.radioControlsBtn(this.radioBtnsList[activeRadioForm]);
		}
	}

	// Переключением между форм регистрации и авторизации
	radioControlsBtn(data) {
		const value = data.nodeName ? data.value : data.target.value;
		if (value === 'signin') {
			this.switchForms(this.Signin, this.Signup);
		} else if (value === 'signup') {
			this.switchForms(this.Signup, this.Signin);
		}
	}

	// управление переключением и сбросом
	switchForms(enabledForm, disabledForm) {
		enabledForm.open();
		disabledForm.close();
		disabledForm.clearForm();
		disabledForm.reset();
	}

	// сброс всего
	resetAll() {
		this.Signin?.reset();
		this.Signup?.reset();
		this.Remember?.reset();
		const firstRadioBtn: HTMLInputElement | undefined = this.radioBtnsList?.[0];
		if (firstRadioBtn) firstRadioBtn.checked = true;
	}

	// Сброс формы восстановления пароля
	resetForgetForm() {
		this.Signin?.open();
		this.radioContainer?.classList.remove(this.formDisableCss);
		this.Remember?.close();
		this.Remember?.resetRemember();
		this.Remember?.clearForm();
	}

	// запрещаем пользователю взаимодействовать с попаом пока идет POST запрос
	disabledAllActions() {
		if (this.container) this.container.style.pointerEvents = 'none';
		document.removeEventListener('keydown', this.escBtnListener);
	}

	// разрешаем пользователю взаимодействовать с попаом после ответа POST запроса
	enabledAllActions() {
		if (this.container) this.container.removeAttribute('style');
		document.addEventListener('keydown', this.escBtnListener);
	}
}
